import React, {useEffect, useState} from "react";
import {Autocomplete, TextField} from "@mui/material";
import {Organization} from "../../autogenerate/api.generated.clients";

export interface OrganizationsAutoCompleteProps extends React.HTMLProps<Element> {
    updateSelectedOrg: Function,
    parentOrganizations: Organization[] | undefined,
    selectedOrganization: Organization | null

}

export default function OrganizationsAutoComplete(props: OrganizationsAutoCompleteProps) {
    const {updateSelectedOrg, parentOrganizations, selectedOrganization} = props;
    const [value, setValue] = useState<string | null>(null);
    const [input, setInput] = useState("");
    const [organizations, setOrganizations] = useState<string[]>([] as string[]);

    useEffect(() => {
        if (parentOrganizations) {
            setOrganizations(parseOrganizations(parentOrganizations as Organization[]));
        }
    }, [parentOrganizations])

    useEffect(() => {
        organizations.map((org) => {
            if (org === selectedOrganization?.name) {
                setValue(org);
            }
        })
    }, [organizations, selectedOrganization]);

    const handleChange = (newValue: string | null) => {
        setValue(newValue);
        let selectedOrganization: Organization = {} as Organization;
        if (newValue != null) {
            selectedOrganization = getOrgObject(newValue)
        }
        updateSelectedOrg(selectedOrganization);
    }

    function getOrgObject(organization: string) {
        let returnOrg: Organization = {} as Organization;
        parentOrganizations?.map(org => {
            if (organization === org.name) {
                returnOrg = org as Organization;
            }
        })
        return returnOrg
    }

    return (
        <Autocomplete
            value={value}
            onChange={(event: any, newValue: string | null) => {
                handleChange(newValue);
            }}
            inputValue={input}
            onInputChange={(event, newInputValue) => {
                setInput(newInputValue);
            }}
            id="parent-organization-autocomplete"
            options={organizations}
            sx={{width: "100%"}}
            renderInput={(params) => <TextField {...params} label="Select an Organization" variant="standard"/>}
        />
    )
}

function parseOrganizations(organizations: Organization[]) {
    let organizationsOptions: string[] = [];
    organizations.map(org => {
        organizationsOptions.push(org.name);
    })
    
    return organizationsOptions;
}