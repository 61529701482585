import {
    Button,
    FormControl,
    IconButton,
    InputAdornment,
    InputLabel,
    OutlinedInput,
    Paper,
} from "@mui/material";
import "./permissionLookup.scss";
import Business from "@mui/icons-material/Business";
import ClearIcon from '@mui/icons-material/Clear';
import {
    useGetOrganizationsWithAccessToCompressor
} from "../../../hooks/Organization/useGetOrganizationsWithAccessToCompressor";
import React, {useEffect, useState} from "react";
import {OrganizationsWithAccessToCompressorDto} from "../../../autogenerate/api.generated.clients";
import UnauthorizedAlert from "../../../components/UnauthorizedAlert/UnauthorizedAlert";
import CompressorNotFoundAlert from "../../../components/CompressorNotFoundAlert/CompressorNotFoundAlert";
import GenericRequestAlert from "../../../components/GenericRequestAlert/GenericRequestAlert";

function PermissionLookup() {
    const permissionLookup = useGetOrganizationsWithAccessToCompressor();
    const [frameSerialNumber, setFrameSerialNumber] = useState("");
    const [orgsWithAccessToCompressor, setOrgsWithAccessToCompressor] = useState<OrganizationsWithAccessToCompressorDto[]>([] as OrganizationsWithAccessToCompressorDto [])
    const [isNotFoundError, setIsNotFoundError] = useState(false);
    const [isNotAuthorizedError, setIsNotAuthorizedError] = useState(false);
    const [isGenericError, setIsGenericError] = useState(false);
    
    useEffect(() => {
        setOrgsWithAccessToCompressor([] as OrganizationsWithAccessToCompressorDto[])
        setIsNotFoundError(false);
        setIsNotAuthorizedError(false);
        setIsGenericError(false);
        
    }, [frameSerialNumber])
    
    const handleErrorResponse = (error: string, errorMessage: string) => {
        switch(error) {
            case "403":
                setIsNotAuthorizedError(true);
                break;
            case "404":
                setIsNotFoundError(true);
                break;
            default:
                setIsGenericError(true);
                break;
        }
    }
    
    const findOrgs = async () => {
        if(frameSerialNumber.length > 0) {
            const response = await permissionLookup.useGetOrganizationsWithAccessToCompressor(frameSerialNumber)
            if(response.isError) {
                handleErrorResponse(response.error!, response.errorMessage!);
            }
            setOrgsWithAccessToCompressor(response.data!);
        }
    }
    
    return (
        <div className={"permission-lookup-container"}>
            <FormControl className={"permission-form-control"} >
                <InputLabel htmlFor={"serial-number-input"}>Input Frame Serial Number</InputLabel>
                <OutlinedInput 
                    className={"serial-number-input"}
                    id="serial-number-input" 
                    label="Input Frame Serial Number" 
                    value={frameSerialNumber}
                    onChange={e => setFrameSerialNumber(e.target.value)}
                    endAdornment={
                        frameSerialNumber.length > 0 ?
                            <InputAdornment position={"end"}>
                                <IconButton
                                    edge="end"
                                    onClick={() => {setFrameSerialNumber("")}}
                                >
                                    <ClearIcon />
                                </IconButton>
                            </InputAdornment>
                        :
                            ""
                    }
    
                />
                <Button 
                    variant={"contained"} 
                    disabled={frameSerialNumber.length == 0}
                    onClick={e => findOrgs()}
                    className={"search-button"}>Search</Button>
            </FormControl>
            {
                frameSerialNumber && orgsWithAccessToCompressor ?
                    <Paper elevation={1} className={"results-surface"}>
                        {
                            orgsWithAccessToCompressor.map((org, index) => {
                                return (
                                    <div key={index} className={"org-item"}>
                                        <Business fontSize={"medium"} />
                                        <p className={"org-name"}>{org.name}</p>
                                    </div>
                                )
                            })
                        }

                    </Paper>
                :
                    ""
            }
            {
                isNotAuthorizedError && <UnauthorizedAlert />
            }
            {
                isNotFoundError && <CompressorNotFoundAlert />
            }
            {
                isGenericError && <GenericRequestAlert /> 
            }
        </div>
    )
}

export default PermissionLookup;