import React, {useEffect, useRef, useState} from 'react';
import {DragAndDropEventArgs, TreeViewComponent} from "@syncfusion/ej2-react-navigations";
import './organizationManager.scss'
import {OrganizationTreeNode} from "./OrganizationTreeNode";
import {
    HierarchyItem,
    HierarchyItemType,
    Organization,
    OrganizationHierarchyItem
} from "../../../autogenerate/api.generated.clients";
import {Button, Dialog, DialogActions, DialogContent, DialogTitle} from "@mui/material";
import OrganizationsAutoComplete from "../../../components/OrganizationsAutoComplete/OrganizationsAutoComplete";
import {useOrganizationHierarchyForOrg} from "../../../hooks/Organization/useOrganizationHierarchy";
import {UserMessage} from "../../../components/UserMessage/UserMessage";
import {CompressorManagerDialog} from "../../../components/CompressorManagerDialog/CompressorManagerDialog";
import {RemoveFrameDialog} from "../../../components/RemoveFrameDialog/RemoveFrameDialog";

export interface OrganizationManagerProps {
    organizations: Organization[]
}

export function OrganizationManager(props: OrganizationManagerProps) {
    const {organizations} = props;
    const [isLoading, setIsLoading] = useState(true);
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const [selectedParentOrg, setSelectedParentOrg] = useState<Organization | null>(null)
    const [isWarningOpen, setIsWarningOpen] = useState(false);
    const [selectedOrg, setSelectedOrg] = useState<OrganizationHierarchyItem | null>(null);
    const [expandedNodes, setExpandedNodes] = useState<string[] | undefined>(undefined);
    const [newHierarchy, setNewHierarchy] = useState<HierarchyItem[]>();
    const [associatedCompressorSn, setAssociatedCompressorsSn] = useState<string[] | null>(null);
    const [hierarchyCompanyId, setHierarchyCompanyId] = useState<number | null>(null)
    const [isDisplayMessage, setIsDisplayMessage] = useState<boolean>(false);
    const [userMessage, setUserMessage] = useState<string>("");
    const [messageSeverity, setMessageSeverity] = useState<string>("");
    const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState<boolean>(false);
    const treeview = useRef<TreeViewComponent>(null);
    const hierarchy = useOrganizationHierarchyForOrg({orgId: parseInt(selectedParentOrg?.externalId ? selectedParentOrg.externalId : "0")});

    useEffect(() => {
        if (organizations) {
            setSelectedParentOrg(organizations[0]);
            setIsLoading(false);
        }
    }, [organizations]);

    useEffect(() => {
        if (hierarchy.data) {
            const collator = new Intl.Collator(undefined, { numeric: true, sensitivity: 'base' });

            setNewHierarchy(hierarchy.data);
            let hierarchyChildren: HierarchyItem[] = (hierarchy.data[0] as any).children.sort((a: any, b: any) => collator.compare(a.name, b.name));
            let hierarchyCompressorSns = hierarchyChildren.map(x => {
                return x.name
            });
            
            setAssociatedCompressorsSn(hierarchyCompressorSns);
            setHierarchyCompanyId(parseInt(hierarchy.data[0].id));
        }
    }, [hierarchy.data]);

    useEffect(() => {
        if (treeview.current && newHierarchy) {
            //@ts-ignore
            treeview.current.fields = {dataSource: newHierarchy, text: 'name', child: 'children'};
            setTimeout(() => {
                treeview.current?.expandAll()
            }, 500);
        }
    }, [newHierarchy])

    useEffect(() => {
        if (treeview.current) {
            treeview.current.expandedNodes = expandedNodes ?? [];
            treeview.current.refresh();
        }
    }, [isDialogOpen, isWarningOpen]);

    function nodeDrag(args: DragAndDropEventArgs): void {
        if (isNotOrgNode(args.droppedNode)) {
            args.dropIndicator = 'e-no-drop';
        }
    }

    function dragStop(args: DragAndDropEventArgs): void {
        if (isNotOrgNode(args.droppedNode)) {
            args.cancel = true;
        }
    }

    const onDeleteOrg = (org: OrganizationHierarchyItem) => {
        setIsWarningOpen(true);
        setSelectedOrg(org);
        setExpandedNodes(treeview.current?.expandedNodes);
    }

    const handleDeleteYes = () => {
        if (selectedOrg && newHierarchy) {
            const updatedHierarchy = newHierarchy.slice();
            const orgs = updatedHierarchy.filter(o => o.type === HierarchyItemType.Org).map(o => o as OrganizationHierarchyItem);
            const recordToUpdate = findOrgById(orgs, selectedOrg) ?? {} as OrganizationHierarchyItem;
            const parentOrg = GetParentOfOrg(orgs, selectedOrg) ?? {} as OrganizationHierarchyItem;
            const childCompressors = GetAllChildrenCompressors(recordToUpdate);
            const indexOfOrg = parentOrg.children.indexOf(recordToUpdate);
            if (indexOfOrg >= 0 && parentOrg) {
                parentOrg?.children.splice(indexOfOrg, 1);
                parentOrg?.children.push(...childCompressors);
            }
            setNewHierarchy(updatedHierarchy);
        }
        handleDialogClose();
    }

    const onRenameOrg = (org: OrganizationHierarchyItem) => {
        if (newHierarchy) {
            setExpandedNodes(treeview.current?.expandedNodes);
            let record = findOrgById(newHierarchy.filter(d => d.type === HierarchyItemType.Org).map(r => r as OrganizationHierarchyItem), org);
            setSelectedOrg(record ?? null);
            setIsDialogOpen(true);
        }
    }

    const handleDialogClose = () => {
        setSelectedOrg(null);
        setIsDialogOpen(false);
        setIsWarningOpen(false);
    }

    const toggleDialogState = () => {
        setIsDialogOpen(!isDialogOpen);
    }

    const toggleIsDeleteDialog = () => {
        setIsDeleteDialogOpen(!isDeleteDialogOpen);
    }

    const handleFrameActionSubmit = async (isOperationSuccess: boolean, successMessageText: string) => {
        setUserMessage(isOperationSuccess
            ?
            successMessageText
            :
            "Error: unable to complete request"
        )
        setMessageSeverity(isOperationSuccess ? "Success" : "Error");
        setIsDisplayMessage(true);
        await hierarchy.refetch();
    }

    const handleAddOrg = (org: OrganizationHierarchyItem) => {
        if (newHierarchy) {
            setExpandedNodes(treeview.current?.expandedNodes);
            let record = findOrgById(newHierarchy.filter(d => d.type === HierarchyItemType.Org).map(r => r as OrganizationHierarchyItem), org);
            setSelectedOrg(record ?? null);
            setIsDialogOpen(true);
        }
    }

    const handleGetFramesForUser = () => {
        setExpandedNodes(treeview.current?.expandedNodes);
        setIsDialogOpen(true);
    }
    const handleUpdateSelectedOrg = (org: Organization) => {
        setSelectedParentOrg(org);
    }

    const onDataSourceChanged = () => {
        const data = treeview.current?.getTreeData();
        if (data) {
            //@ts-ignore
            setNewHierarchy(data as OrganizationHieracrhyItem[]);
        }
    }
    const toggleDisplayMessage = () => {
        setIsDisplayMessage(!isDisplayMessage);
    }

    const treeNode = (data: any) => <OrganizationTreeNode onAddOrg={handleAddOrg} onDelete={onDeleteOrg}
                                                          onRename={onRenameOrg} onAddCompressors={handleGetFramesForUser}
                                                          onDeleteCompressors={toggleIsDeleteDialog} data={data}/>;

    return (
        <div className="org-management-container">
            <UserMessage displayMessage={isDisplayMessage}
                         messageContent={userMessage} messageSeverity={messageSeverity}
                         toggleDisplayMessage={toggleDisplayMessage}/>
            <div className="select-parent-organization">
                <OrganizationsAutoComplete updateSelectedOrg={handleUpdateSelectedOrg}
                                           parentOrganizations={organizations}
                                           selectedOrganization={selectedParentOrg}/>
            </div>
            {
                !isLoading && selectedParentOrg ?
                    <div className="e-card hierarchy-container">
                        <TreeViewComponent ref={treeview}
                                           nodeDragging={nodeDrag} enablePersistence
                                           nodeDragStop={dragStop} allowDragAndDrop allowMultiSelection
                                           nodeTemplate={treeNode}
                                           dataSourceChanged={onDataSourceChanged}
                                           expandedNodes={expandedNodes}
                        />
                    </div>
                    : ''
            }
            <CompressorManagerDialog
                orgId={selectedParentOrg?.externalId}
                orgAssociatedCompressorSns={associatedCompressorSn}
                isDialogOpen={isDialogOpen}
                toggleDialogState={toggleDialogState}
                handleFrameSubmit={handleFrameActionSubmit}
            />
            <RemoveFrameDialog
                orgId={selectedParentOrg?.externalId}
                orgFrameSns={associatedCompressorSn}
                isDialogOpen={isDeleteDialogOpen}
                toggleIsDialogOpen={toggleIsDeleteDialog}
                handleFrameDelete={handleFrameActionSubmit}
            />
            <Dialog
                open={isWarningOpen}
                maxWidth="sm"
                fullWidth={true}
                onClose={handleDialogClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    Are you sure?
                </DialogTitle>
                <DialogContent>
                    Deleting an organization also deletes all child organizations. <br/><br/>Compressors be assigned to
                    the parent organization.
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleDialogClose} autoFocus>
                        No
                    </Button>
                    <Button onClick={handleDeleteYes} autoFocus>
                        Yes
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}

function findOrgById(organizations: OrganizationHierarchyItem[], org: OrganizationHierarchyItem): OrganizationHierarchyItem | undefined {
    if (!organizations)
        return undefined;
    let record = organizations?.find(o => o.id === org.id);
    if (!record) {
        const childOrgs = organizations.map(o => {
            return o.children.filter(c => c.type === HierarchyItemType.Org).map(o => o as OrganizationHierarchyItem)
        }).flatMap(x => x);
        record = findOrgById(childOrgs, org);
    }
    return record;
}

function GetAllChildrenCompressors(parentOrg: OrganizationHierarchyItem | undefined) {
    if (!parentOrg?.children.length)
        return [];
    const compressors: HierarchyItem[] = parentOrg?.children.filter(c => c.type === HierarchyItemType.Frame).map(c => c as OrganizationHierarchyItem);
    parentOrg.children.forEach(c => {
        if (c.type === HierarchyItemType.Org) {
            compressors.push(...GetAllChildrenCompressors(c as OrganizationHierarchyItem))
        }
    });

    return compressors;
}

function isNotOrgNode(node: HTMLLIElement) {
    const orgClass: string = HierarchyItemType.Org.toString().toLowerCase();
    return node != null && node.getElementsByClassName(orgClass) && node.getElementsByClassName(orgClass).length === 0
}

function GetParentOfOrg(organizations: OrganizationHierarchyItem[] | undefined, org: OrganizationHierarchyItem): OrganizationHierarchyItem | undefined {
    if (!organizations || organizations.find(c => c.id == org.id))
        return undefined;
    let parentOrg = organizations?.find(o => o.children.find(c => c.id == org.id));
    if (!parentOrg) {
        const childOrgs = organizations.map(o => {
            return o.children.filter(c => c.type === HierarchyItemType.Org).map(o => o as OrganizationHierarchyItem)
        }).flatMap(x => x);
        parentOrg = GetParentOfOrg(childOrgs, org);
    }
    return parentOrg;
}

export default OrganizationManager;