import {ButtonComponent} from "@syncfusion/ej2-react-buttons";
import "./DataExport.scss";
import {utils, writeFile} from 'xlsx';
import {ChartingDataParams, ChartingVariable} from "../../autogenerate/api.generated.clients";
import {useChartingDataExport} from "../../hooks/Compressor/Reports/Charting/useChartingDataExport";


interface DataExportProps {
    data: ChartingDataParams,
    reportTitle: string,
}

export function DataExport(props: DataExportProps) {
    const {data, reportTitle} = props;
    const getChartData = useChartingDataExport();
    const OnExport = async () => {
        let result = await getChartData.chartData(data);
        //Format dates to be in local time
        result.forEach((item: any) => {
            if (item["Timestamp"]) {
                item["Timestamp"] = new Date(item["Timestamp"]).toLocaleDateString('en-US', {
                    hour12: true,
                    year: '2-digit',
                    month: 'numeric',
                    day: 'numeric',
                    hour: 'numeric',
                    minute: 'numeric',
                });
            }
        });
        result.sort((a: any, b: any) => new Date(a["TimeStamp"]).valueOf() - new Date(b["TimeStamp"]).valueOf())
        let workbookName = reportTitle + '.xlsx';
        let workbook = utils.book_new();
        if(result.length > 0) {
        let worksheet = utils.json_to_sheet(result);
        utils.book_append_sheet(workbook, worksheet);
        writeFile(workbook, workbookName);
        }
    }
    
    if(!data.variables) {
        return <div/>
    }

    return (
        <div>
            <ButtonComponent className="data-export-button" isPrimary onClick={OnExport}>Export</ButtonComponent>
        </div>
    )
}