import {Alert} from "@mui/material";

function GenericRequestAlert() {
    return (
        <Alert severity="error">
            There has been an error with your request.
        </Alert>
    )
}

export default GenericRequestAlert;