import {ApiException, Client, OrganizationsWithAccessToCompressorDto} from "../../autogenerate/api.generated.clients";

export interface GetOrganizationsWithAccessToCompressor {
    data: null | OrganizationsWithAccessToCompressorDto[];
    isError: boolean;
    error: null | string;
    errorMessage: null | string;
}
export function useGetOrganizationsWithAccessToCompressor() {
    return {
        useGetOrganizationsWithAccessToCompressor: async (frameSerialNumner: string) => {
            try {
                const client = new Client();
                const data = await client.organization_GetOrganizationsWithAccessToCompressor(frameSerialNumner);
                return {data: data, isError: false, error: null, errorMessage: null} as GetOrganizationsWithAccessToCompressor;
            }
            catch (error) {
                if(error instanceof ApiException) {
                    return {data: null, isError: true, error: error.status.toString(), errorMessage: error.message} as GetOrganizationsWithAccessToCompressor;
                } 
                else {
                    return {data: null, isError: true, error: "500", errorMessage: "An unknown error occurred."} as GetOrganizationsWithAccessToCompressor;
                }
            }
        }
    };
}