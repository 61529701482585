import React from 'react';
import {useParams} from "react-router-dom";
import useVibrationData from "../../../hooks/Compressor/Vibration/useVibrationData";
import {GridSkeleton} from "../../../components/Skeletons/GridSkeleton";
import {Skeleton, Table, TableCell, TableFooter, TableHead, TableRow, Typography} from "@mui/material";
import './vibrationTab.scss'
import {VibrationOverview} from "../../../autogenerate/api.generated.clients";
import ValueNotReceivedMessage from "../../../components/ValueNotReceivedMessage/ValueNotReceivedMessage";
import ErrorBoundary from "../../../components/ErrorBoundary/ErrorBoundary";
import DerivedPeakTable from "./DerivedPeakTable";

function VibrationTab() {
    const params = useParams();
    const frameSerialNumber = params.frameSerialNumber!;
    const vibrationData = useVibrationData({frameSerialNumber});
    
    return (
        <div className={'vibration-tab-content'}>
            <div className={`vibration-table-card e-card flex-sm dashboard-card`}>
                <Typography className={'title'}>Frame vibration</Typography>
                <ErrorBoundary errorComponent={<ValueNotReceivedMessage/>}>
                    <DerivedPeakTable data={vibrationData.data?.frameOverview} isLoading={vibrationData.isLoading}/>
                </ErrorBoundary>
            </div>
            <div className={`vibration-table-card e-card flex-sm dashboard-card`}>
                <Typography className={'title'}>Torsional vibration</Typography>
                <ErrorBoundary errorComponent={<ValueNotReceivedMessage/>}>
                    <DerivedPeakTable data={vibrationData.data?.torsionalOverview} isLoading={vibrationData.isLoading}/>
                </ErrorBoundary>
            </div>
        </div>
    );
}


export default VibrationTab;