import {Alert} from "@mui/material";

function CompressorNotFoundAlert() {
    return (
        <Alert severity='info'>
            Compressor does not exist or is not an ASC enabled compressor.
        </Alert>
    )
}

export default CompressorNotFoundAlert;